import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, map } from 'rxjs';
import { environment } from 'src/config/environment';
import { AuthInterceptorSkipHeader } from '../../constants/auth/auth-interceptor-skip-header';
import { SUPPORTED_COUNTRIES } from '../../constants/utilities/supported-countries';
import { CountryCodeHelper } from '../../helpers/country-code-helper';
import { Address } from '../../interfaces/common/address';
import { Coordinates } from '../../interfaces/common/coordinates';
import { MapCoordinates } from '../../interfaces/utilities/here-maps/map-coordinates';
import { MapRoute } from '../../interfaces/utilities/here-maps/map-route';
import { MapRouteSection } from '../../interfaces/utilities/here-maps/map-route-section';
import { MapRouteSummary } from '../../interfaces/utilities/here-maps/map-route-summary';
import { MapRouteTolls } from '../../interfaces/utilities/here-maps/map-route-tolls';
import { MapRoutingSettings } from '../../interfaces/utilities/here-maps/map-routing-settings';
import { MapWaypoint } from '../../interfaces/utilities/here-maps/map-waypoint';

@Injectable({
  providedIn: 'root',
})
export class HereMapsService {
  private readonly apiKey: string = environment.hereMapsApiKey;

  private platform?: H.service.Platform;

  constructor(private http: HttpClient) {}

  findRoute(
    waypoints: MapWaypoint[],
    settings: MapRoutingSettings,
  ): Observable<MapRoute[]> {
    const waypointStrings = waypoints.map((waypoint: MapWaypoint) => {
      const { lat, lon } = waypoint.address;
      return `${lat},${lon}`;
    });

    let params = new HttpParams().append('origin', waypointStrings[0]);
    for (const waypointString of waypointStrings.slice(1, -1)) {
      params = params.append('via', waypointString);
    }
    params = params.append('destination', waypointStrings.at(-1)!);

    params = params
      .append('transportMode', settings.ev ? 'car' : 'truck')
      .append('lang', 'pl-PL')
      .append('currency', 'EUR')
      .append('routingMode', settings.routingMode)
      .append('return', settings.return.join(','))
      .append('apiKey', this.apiKey);

    if (settings.departureTime) {
      params = params.append(
        'departureTime',
        new Date(settings.departureTime).toISOString(),
      );
    }

    if (settings.alternatives) {
      params = params.append('alternatives', settings.alternatives);
    }

    if (!settings.ev) {
      const DEFAULT_AVOID_FEATURES = ['dirtRoad', 'difficultTurns'];
      settings.avoidFeatures = [
        ...DEFAULT_AVOID_FEATURES,
        ...(settings.avoidFeatures ?? []),
      ];
      params = params.append(
        'avoid[features]',
        settings.avoidFeatures.join(','),
      );
    }

    if (settings.excludeCountries?.length) {
      params = params.append(
        'exclude[countries]',
        settings.excludeCountries
          .map((code) => CountryCodeHelper.toAlpha3(code.toUpperCase()))
          .join(','),
      );
    }

    if (settings.ev) {
      const ev = settings.ev;
      const maxChargeAfterChargingStation = Math.min(
        Math.round((ev.maxChargePercentage * ev.batteryCapacity) / 100),
        ev.batteryCapacity,
      );

      params = params
        .append('ev[freeFlowSpeedTable]', '0,0.28')
        .append(
          'ev[initialCharge]',
          Math.min(
            (ev.batteryPercentage * ev.batteryCapacity) / 100,
            ev.batteryCapacity,
          ),
        )
        .append('ev[maxCharge]', ev.batteryCapacity)
        .append('ev[connectorTypes]', ev.connectors.join(','))
        .append(
          'ev[chargingCurve]',
          `0,100,${maxChargeAfterChargingStation},100`,
        )
        .append(
          'ev[maxChargeAfterChargingStation]',
          maxChargeAfterChargingStation,
        )
        .append('ev[makeReachable]', true);
    }

    const headers = new HttpHeaders().set(AuthInterceptorSkipHeader, '');

    const lastCompletedWaypoint = cloneDeep(waypoints)
      .reverse()
      .find((w) => w.completed === true);

    return this.http
      .get<any>(`https://router.hereapi.com/v8/routes`, { params, headers })
      .pipe(
        map((response) => {
          const routes: MapRoute[] = [];

          for (const route of response.routes) {
            const sections: MapRouteSection[] = [];

            let totalDuration = 0;
            let totalDistance = 0;

            let totalTolls = 0;
            const tollsByCountryMap = new Map<string, number>();
            const tollsBySystemMap = new Map<string, number>();

            let lastCompletedSectionIndex: number | undefined;
            if (lastCompletedWaypoint) {
              const index = cloneDeep(route.sections as any[])
                .reverse()
                .findIndex((section) => {
                  const EPSILON = 0.0005;
                  const location = section.arrival.place
                    .location as MapCoordinates;

                  const latDiff = Math.abs(
                    location.lat - lastCompletedWaypoint.address.lat,
                  );
                  const lngDiff = Math.abs(
                    location.lng - lastCompletedWaypoint.address.lon,
                  );

                  return latDiff < EPSILON && lngDiff < EPSILON;
                });
              lastCompletedSectionIndex = index === -1 ? undefined : index;
            }

            for (const [sectionIndex, section] of route.sections.entries()) {
              let summary: MapRouteSummary | undefined;
              if (settings.return.includes('summary')) {
                summary = {
                  duration: section.summary.duration,
                  distance: section.summary.length,
                };
                totalDuration += summary.duration;
                totalDistance += summary.distance;
              }

              sections.push({
                departure: {
                  location: section.departure.place.location,
                  time: section.departure.time,
                },
                arrival: {
                  location: section.arrival.place.location,
                  time: section.arrival.time,
                },
                polyline: section?.polyline,
                completed:
                  lastCompletedSectionIndex != null &&
                  sectionIndex <= lastCompletedSectionIndex,
                charging: (section as any).postActions?.some(
                  (a: any) => a.action === 'charging',
                ),
                summary,
              });

              if (settings.return.includes('tolls')) {
                totalTolls +=
                  section?.tolls?.reduce((tollsSum: number, toll: any) => {
                    const fare = toll.fares.reduce((prev: any, curr: any) => {
                      return prev.convertedPrice.value <
                        curr.convertedPrice.value
                        ? prev
                        : curr;
                    });

                    const farePrice = fare.convertedPrice.value;

                    const totalCountryTolls =
                      tollsByCountryMap.get(toll.countryCode) ?? 0;
                    tollsByCountryMap.set(
                      toll.countryCode,
                      totalCountryTolls + farePrice,
                    );

                    const totalSytemTolls =
                      tollsBySystemMap.get(toll.tollSystem) ?? 0;
                    tollsBySystemMap.set(
                      toll.tollSystem,
                      totalSytemTolls + farePrice,
                    );

                    return (tollsSum += farePrice);
                  }, 0) ?? 0;
              }
            }

            let summary: MapRouteSummary | undefined;
            if (settings.return.includes('summary')) {
              summary = {
                duration: totalDuration,
                distance: totalDistance,
              };
            }

            let tolls: MapRouteTolls | undefined;
            if (settings.return.includes('tolls')) {
              tolls = {
                tolls: totalTolls,
                tollsByCountry: Array.from(
                  tollsByCountryMap,
                  ([key, value]) => ({ key, value }),
                ).sort((a, b) => b.value - a.value),
                tollsBySystem: Array.from(tollsBySystemMap, ([key, value]) => ({
                  key,
                  value,
                })).sort((a, b) => b.value - a.value),
              };
            }

            routes.push({
              id: route.id,
              waypoints,
              sections,
              summary,
              tolls,
            });
          }

          return routes;
        }),
      );
  }

  getPlacePredictions(
    query: string,
    exact: boolean,
    limit = 5,
  ): Observable<Address[]> {
    const params = new HttpParams()
      .append('q', query)
      .append('limit', limit.toString())
      .append('apiKey', this.apiKey);
    const headers = new HttpHeaders().set(AuthInterceptorSkipHeader, '');

    return this.http
      .get('https://geocode.search.hereapi.com/v1/geocode', {
        params,
        headers,
      })
      .pipe(
        map((response: any): Address[] => {
          const items = response.items.filter(
            (item: any) =>
              (!exact || item.resultType === 'houseNumber') &&
              SUPPORTED_COUNTRIES.includes(item.address.countryCode),
          );

          return items.map(this.mapHereAddress);
        }),
      );
  }

  getReversePlacePredictions(coordinates: Coordinates): Observable<Address[]> {
    const { lat, lon } = coordinates;

    const params = new HttpParams()
      .append('at', `${lat},${lon}`)
      .append('limit', 1)
      .append('lang', 'pl')
      .append('apiKey', this.apiKey);
    const headers = new HttpHeaders().set(AuthInterceptorSkipHeader, '');

    return this.http
      .get('https://revgeocode.search.hereapi.com/v1/revgeocode', {
        params,
        headers,
      })
      .pipe(map((response: any) => response.items.map(this.mapHereAddress)));
  }

  private mapHereAddress(item: any): Address {
    const { label, city, houseNumber, postalCode, street } = item.address;
    const countryCode = CountryCodeHelper.toAlpha2(item.address.countryCode);

    let address: string;
    if (!houseNumber) {
      address = street;
    } else {
      const numberBeforeStreet = `${houseNumber} ${street}`;
      const numberAfterStreet = `${street} ${houseNumber}`;

      if (label.includes(numberBeforeStreet)) {
        address = numberBeforeStreet;
      } else {
        address = numberAfterStreet;
      }
    }

    return {
      lat: item.position.lat,
      lon: item.position.lng,
      street: address || null,
      countryCode,
      city: city || null,
      postcode: postalCode || null,
    };
  }

  getPlatform(): H.service.Platform {
    this.platform ??= new H.service.Platform({
      apikey: this.apiKey,
    });
    return this.platform;
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  Output,
  ViewChild,
  forwardRef,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputColor } from 'src/app/core/interfaces/utilities/input-color';
import { BaseFormControlComponent } from '../../forms/base-form-control.component';

const INTEGER_REGEXP = /^\d+$/;
const FLOAT_REGEXP = /^\d+[,.]?\d{0,2}$/;

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent<T>
  extends BaseFormControlComponent
  implements ControlValueAccessor, AfterViewInit
{
  @ViewChild('input') inputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('unitRef') unitRef?: ElementRef<HTMLParagraphElement>;

  @HostBinding('style.width') @Input() width = '100%';

  @Input() type = 'text';
  @Input() color: InputColor = 'white';
  @Input() textAlign: 'start' | 'center' | 'end' = 'start';
  @Input() label?: string;
  @Input() height = 40;
  @Input() autocomplete?: string;
  @Input() placeholder?: string;
  @Input() maxLength?: number;
  @Input() unit?: string;
  @Input() disabled = false;
  @Input() integer = false;
  @Input() float = false;
  @Input() errors = true;

  @Input() set value(value: T | null) {
    if (value == null) {
      this.value_.set(undefined);
      setTimeout(() => (this.inputRef.nativeElement.value = ''));
      return;
    }

    if (this.integer || this.float) {
      const pattern = this.integer ? INTEGER_REGEXP : FLOAT_REGEXP;
      if (!pattern.test(value.toString())) return;
    }

    this.value_.set(value);
    setTimeout(() => (this.inputRef.nativeElement.value = value.toString()));
  }
  get value(): T | null {
    return this.value_() ?? null;
  }

  @Output() valueChange = new EventEmitter<T | null>();

  protected paddingRight = signal(10);

  private value_ = signal<T | undefined>(undefined);

  constructor(
    protected override injector: Injector,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(injector);
  }

  onChange?: (value: T | null) => void;
  onTouched?: () => void;

  registerOnChange(fn: (value: T | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: T | null): void {
    this.value = value;
  }

  @HostListener('focusout')
  handleFocusout(): void {
    this.onTouched?.();
  }

  ngAfterViewInit(): void {
    if (this.unit) {
      this.paddingRight.set(
        this.unitRef
          ? this.unitRef.nativeElement.clientWidth + 12
          : this.unit.length * 8 + 12
      );
      this.changeDetectorRef.detectChanges();
    }
  }

  focus(): void {
    this.inputRef.nativeElement.focus();
  }

  validateInput(event: KeyboardEvent): boolean {
    if (!(this.integer || this.float)) return true;

    const target = event.target as HTMLInputElement;
    const { value, selectionStart, selectionEnd } = target;

    const newValue =
      value.slice(0, selectionStart!) + event.key + value.slice(selectionEnd!);

    const pattern = this.integer ? INTEGER_REGEXP : FLOAT_REGEXP;
    if (!pattern.test(newValue)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  validatePaste(event: ClipboardEvent): boolean {
    if (!(this.integer || this.float)) return true;

    const target = event.target as HTMLInputElement;
    const { value, selectionStart, selectionEnd } = target;
    const pasteData = event.clipboardData?.getData('text') ?? '';

    const newValue =
      value.slice(0, selectionStart!) + pasteData + value.slice(selectionEnd!);

    const pattern = this.integer ? INTEGER_REGEXP : FLOAT_REGEXP;
    if (pattern.test(newValue)) {
      const numberValue = Number(newValue);
      this.onChange?.(numberValue as T);
      this.valueChange.emit(numberValue as T);
      this.inputRef.nativeElement.value = newValue;
    }

    event.preventDefault();
    return false;
  }

  handleValueChange(event: Event): void {
    let value = (event.target as HTMLInputElement).value;

    if (this.integer || this.float) {
      value = value.replace(',', '.').replace(/^0(?=[0-9])/, '');
      const pattern = this.integer ? INTEGER_REGEXP : FLOAT_REGEXP;

      if (!value) {
        this.onChange?.(null);
        this.valueChange.emit(null);
        return;
      } else if (pattern.test(value)) {
        const numberValue = Number(value);
        this.onChange?.(numberValue as T);
        this.valueChange.emit(numberValue as T);
      }

      this.inputRef.nativeElement.value = value;
    } else {
      this.onChange?.(value as T);
      this.valueChange.emit(value as T);
    }
  }
}
